//#region Imports
import React, { useState, useEffect, Fragment } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";

//Vapor Components
import Stack from "@vapor/react-material/Stack";
import Divider from "@vapor/react-material/Divider";
import Button from "@vapor/react-material/Button";
import Alert from "@vapor/react-material/Alert";
import Box from "@vapor/react-material/Box";
import TextField from "@vapor/react-material/TextField";
import Autocomplete from "@vapor/react-material/Autocomplete";
import FormGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Switch from "@vapor/react-material/Switch";
import IconButton from "@vapor/react-material/IconButton";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import TableContainer from "@vapor/react-material/TableContainer";
import TableBody from "@vapor/react-material/TableBody";
import TableRow from "@vapor/react-material/TableRow";
import Table from "@vapor/react-material/Table";
import TableCell from "@vapor/react-material/TableCell";
import Paper from "@vapor/react-material/Paper";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import ExtendedAccordion from "@vapor/react-extended/ExtendedAccordion";

//Vapor Date Pickers
import { AdapterDateFns } from "@vapor/react-x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@vapor/react-x-date-pickers/LocalizationProvider";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";
import { it } from "date-fns/locale";

//Icons
import {
  Add as AddIcon,
  SaveOutlined as SaveIcon,
  CloseOutlined as CancelIcon,
  ModeEdit as ModeEditIcon,
  Delete as DeleteIcon,
  Sos as SosIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../Loading";
import { NoData } from "../NoData";
import { ModalDelete } from "../ModalDelete";

//Constants, Api and Data Models
import {
  isDateValid,
} from "../../businessLogic/bl";

import {
  userModel,
  baseIndicatoreAnomaliaModel,
  baseTipoAnagraficaModel,
  indicatoreAnomaliaModel,
} from "../../businessLogic/models";

import {
  query_users_byTenantId,
  query_users_byCompanyId,
  query_base_all,
  query_base_indicatore_anomalia,
  query_prestazioni_byFascicolo,
  query_indicatori_anomalia_byFascicolo,
  query_anagrafiche_byFascicolo,
  query_base_tipo_anagrafica,
} from "../../businessLogic/query";

import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_indicatore_anomalia,
  mutation_update_indicatore_anomalia,
  mutation_delete_indicatore_anomalia,
  mutation_update_fascicolo,
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";
import postal from "postal";
//#endregion

export const IndicatoreAnomaliaForm = ({
  fascicolo,
  reloadStatusFascicolo,
}) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  //#region Query
  const apiUsersByTenantId = useQuery(
    query_users_byTenantId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUsersByCompanyId = useQuery(
    query_users_byCompanyId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseAll = useQuery(
    query_base_all,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseIndAnomalia = useQuery(
    query_base_indicatore_anomalia,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPrestazioniByFascicolo = useQuery(
    query_prestazioni_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiIndicatoriByFascicolo = useQuery(
    query_indicatori_anomalia_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficaByFascicolo = useQuery(
    query_anagrafiche_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoAnagrafica = useQuery(
    query_base_tipo_anagrafica,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  //#endregion

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddIndicatore = useMutation(mutation_add_indicatore_anomalia, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateIndicatore = useMutation(mutation_update_indicatore_anomalia, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteIndicatore = useMutation(mutation_delete_indicatore_anomalia, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateFascicolo = useMutation(mutation_update_fascicolo, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  //#endregion

  //#region UseState 
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api

  //Professionisti
  const [allUtentiList, setAllUtentiList] = useState([]);
  const [utentiList, setUtentiList] = useState([]);
  const [isUserAllowedToManage] = useState(true);

  //Tabelle di base
  const [baseIndAnomaliaList, setBaseIndAnomaliaList] = useState([]); //base_indicatore_anomalia

  const [showDeleteModal, setShowDeleteModal] = useState(false); //Modal per la conferma di eliminazione di un record
  const [idToPerform, setIdToPerform] = useState(0); //Contiene l'id del record da visualizzare

  //Indicatore anomalia corrente
  const [showIndAnomaliaForm, setShowIndAnomaliaForm] = useState(false);
  const [currentIndicatoriList, setCurrentIndicatoriList] = useState([]); //Contiene gli indicatori correnti
  const [currentIndicatore, setCurrentIndicatore] = useState(
    new indicatoreAnomaliaModel(
      0,
      new Date(),
      loggedUserId,
      [],
      "",
      new Date(),
      null,
      loggedUserId,
      null
    )
  ); //Contiene l'indicatore corrente
  const [currentIndicatoreOriginal, setCurrentIndicatoreOriginal] = useState(
    new indicatoreAnomaliaModel(
      0,
      new Date(),
      loggedUserId,
      [],
      "",
      new Date(),
      null,
      loggedUserId,
      null
    )
  ); //Used for Audit

  const [accordionExpandedArr, setAccordionExpandedArr] = useState([]);
  const [anagraficaDataIdent, setAnagraficaDataIdent] = useState(null);
  //#endregion

  useEffect(() => {
    loadDatas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  //#region Load functions
  async function loadDatas() {
    try {
      setBackDropOpen(true);

      if (!isDataLoaded) {
        /**************************************/
        //Carico i permessi relativi all'utente        
        const allUsersResponse = await apiUsersByTenantId.fetch({
          tenantId: tenantId,
        });
        const arrAllUsers = [];
        allUsersResponse.data.data.user_tenant.map((item) =>
          arrAllUsers.push(
            new userModel(
              item.UserId,
              item.TenantId,
              item.user.Username,
              item.user.Email,
              item.user.Name,
              item.user.Surname,
              item.user.Name + " " + item.user.Surname,
              item.Type,
              item.user.Locale,
              item.TenantTipoId,
              item.user.IsRespAntiRic,
              item.base_tipo_tenant.Value,
              item.user.IsActive,
              item.user.IsDeleted,
              item.user.IsBanned,
              item.user.CreatedOn,
              item.user.Cf
            )
          )
        );
        setAllUtentiList(arrAllUsers);

        /**************************************/
        //Carico gli utenti
        const usersResponse = await apiUsersByCompanyId.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
        });
        const arrUsers = [];
        usersResponse.data.data.user_tenant.map((item) =>
          arrUsers.push(
            new userModel(
              item.UserId,
              item.TenantId,
              item.user.Username,
              item.user.Email,
              item.user.Name,
              item.user.Surname,
              item.user.Name + " " + item.user.Surname,
              item.Type,
              item.user.Locale,
              item.TenantTipoId,
              item.user.IsRespAntiRic,
              item.base_tipo_tenant.Value,
              item.user.IsActive,
              item.user.IsDeleted,
              item.user.IsBanned,
              item.user.CreatedOn,
              item.user.Cf
            )
          )
        );

        /**************************************/
        //Carico le tabelle di base
        /*base_indicatore_anomalia*/
        const tbBaseIndAnomaliaResponse = await apiBaseIndAnomalia.fetch();
        const arrIndAnomaliaItems = [];
        const arrAccordionExpandedItems = [];
        tbBaseIndAnomaliaResponse.data.data.base_indicatore_anomalia.forEach(
          (item) => {
            arrIndAnomaliaItems.push(
              new baseIndicatoreAnomaliaModel(
                item.Id,
                item.ParentId,
                item.Section,
                item.Point,
                item.SubPoint,
                item.Description,
                item.IsVisible
              )
            );

            if (item.ParentId === 0) {
              arrAccordionExpandedItems.push({
                id: item.Point,
                expanded: false,
              });
            }
          }
        );
        setBaseIndAnomaliaList(arrIndAnomaliaItems);
        setAccordionExpandedArr(arrAccordionExpandedItems);

        /*base_tipo_anagrafica*/
        const arrTipoAnagItems = [];
        const tbBaseAllResponse = await apiBaseAll.fetch({
          tenantId: tenantId,
        });
        tbBaseAllResponse.data.data.base_tipo_anagrafica.map((item) =>
          arrTipoAnagItems.push(
            new baseTipoAnagraficaModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem
            )
          )
        );

        //Recupero gli utenti che hanno almeno una prestaziona relativa al fascicolo
        const newArrUsers = [];
        const prestFascResponse = await apiPrestazioniByFascicolo.fetch({
          fascicoloId: fascicolo.id,
        });
        if (prestFascResponse.data.errors !== undefined) {
          enqueueSnackbar(t("error.generale"), { variant: "error" });
        } else {
          prestFascResponse.data.data.prestazione.forEach((item) => {
            item.prestazione_users.forEach((itemU) => {
              arrUsers.forEach((item3) => {
                if (
                  itemU.UserId === item3.userId &&
                  newArrUsers.filter((x) => x.userId === itemU.UserId).length <=
                    0
                ) {
                  newArrUsers.push(item3);
                }
              });
            });
          });
        }
        setUtentiList(newArrUsers);

        /**************************************/
        //Carico gli indicatori relativi al fascicolo
        if (fascicolo.id > 0) {
          const indResponse = await apiIndicatoriByFascicolo.fetch({
            fascicoloId: fascicolo.id,
          });
          if (indResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Carico gli indicatori di anomalia
            const arrItems = [];

            for (
              let i = 0;
              i < indResponse.data.data.indicatore_anomalia.length;
              i++
            ) {
              const item = indResponse.data.data.indicatore_anomalia[i];
              let itemModel = new indicatoreAnomaliaModel(
                item.Id,
                item.Date,
                item.ProvidedBy,
                JSON.parse(item.Result),
                item.Note,
                item.CreatedOn,
                item.ModifiedOn,
                item.CreatedBy,
                item.ModifiedBy
              );

              arrItems.push(itemModel);
            }

            setCurrentIndicatoriList(arrItems);

            //Recupero l'ultima identificazione del cliente collegato al fascicolo per ottenere la data di identificazione
            let dataIdent = null;
            const anagResponse = await apiAnagraficaByFascicolo.fetch({
              fascicoloId: fascicolo.id,
            });
            if (anagResponse.data.errors !== undefined) {
            } else {
              //Recupero la prima versione dell'anagrafica
              let toTake = []
              anagResponse.data.data.anagrafica.forEach(anag => {
                if(anag.anagrafica_fascicolos.filter(af => af.TipoAnagId === 1 || af.TipoAnagId === 2).length > 0)
                  toTake.push(anag)
              })
              let item = toTake.filter(x=> x.VersionN === 1)[0]

              for (let n = 0; n < item.anagrafica_fascicolos.length; n++) {
                const anagFasc = item.anagrafica_fascicolos[n];
                const tipoAnagKey = arrTipoAnagItems.filter(
                  (bta) => bta.id === anagFasc.base_tipo_anagrafica.Id
                )[0].key;

                if (
                  tipoAnagKey === "tipo_anagrafica_1" ||
                  tipoAnagKey === "tipo_anagrafica_2"
                ) {
                  if (item.anagrafica_identificaziones.length > 0) {
                    const itemI = item.anagrafica_identificaziones[0];
                    dataIdent = new Date(Date.parse(itemI.IdentifiedOn));
                  }
                }
              }
            }

            if (dataIdent !== null){ 
                setAnagraficaDataIdent(dataIdent);
            }            
          }
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "IndicatoreAnomaliaForm",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }
  //#endregion

  //#region Handler
  const handleChange = async (event, value) => {
    const id =
      event.target.id !== undefined ? event.target.id : event.target.name;

    //Contiene un'array con la coppia indicatore/selezione
    let result = Object.assign([], currentIndicatore.result);

    if (id.indexOf("ddlUtenti-option") !== -1 || id === "ddlUtenti") {
      currentIndicatore.providedBy = value.userId;
    } else if (id.indexOf("switch") !== -1) {
      const isChecked = event.target.checked;
      const itemId = id.replace("switch", ""); //è nel formato switch1.0     
      const itemPoint = parseInt(itemId.split(".")[0]);
      const itemSubPoint = parseInt(itemId.split(".")[1]);

      //Recupero l'elemento dall'array principale
      const indicatoreItem = baseIndAnomaliaList.filter(
        (x) => x.point === itemPoint && x.subPoint === itemSubPoint
      )[0];

      //Aggiorno l'array result
      const arrId = indicatoreItem.point + "." + indicatoreItem.subPoint;
      const resultItem = { id: arrId, checked: isChecked };
      const objIndex = result.findIndex((obj) => obj.id === arrId);
      result[objIndex] = resultItem;

      //Se la selezione riguarda un nodo padre, e non è checkato, allora setto a false anche i nodi figli
      if (indicatoreItem.parentId === 0 && !isChecked) {
        const childs = baseIndAnomaliaList.filter(
          (x) => x.parentId === indicatoreItem.id
        );
        for (let i = 0; i < childs.length; i++) {
          const childArrId = childs[i].point + "." + childs[i].subPoint;
          const resultChildItem = { id: childArrId, checked: isChecked };
          const objChildIndex = result.findIndex((obj) => obj.id === childArrId);
          result[objChildIndex] = resultChildItem;
        }
      }

      //Apro/Chiudo gli accordion in base al valore isChecked
      if (indicatoreItem.parentId === 0) {
        const accordionItem = { id: indicatoreItem.point, expanded: isChecked };
        let accordionArr = Object.assign([], accordionExpandedArr);
        const accordionIndex = accordionArr.findIndex(
          (obj) => obj.id === indicatoreItem.point
        );
        accordionArr[accordionIndex] = accordionItem;
        setAccordionExpandedArr(accordionArr);
      }

      currentIndicatore.result = result;
    } else if (id === "txtNote") {
      currentIndicatore.note = event.target.value;
    }

    setCurrentIndicatore(currentIndicatore);    
  };
  const handleChangeDate = async (date) => {
    const tbTipoAnagResponse = await apiBaseTipoAnagrafica.fetch({
      tenantId: tenantId,
    });
    const arrTipoAnagItems = [];
    tbTipoAnagResponse.data.data.base_tipo_anagrafica.map((item) =>
      arrTipoAnagItems.push(
        new baseTipoAnagraficaModel(
          item.Id,
          item.Key,
          item.Value,
          item.Order,
          item.IsSystem
        )
      )
    );
    const tipoAnagId1 = arrTipoAnagItems.filter(
      (x) => x.key === "tipo_anagrafica_1"
    )[0].id;
    const tipoAnagId2 = arrTipoAnagItems.filter(
      (x) => x.key === "tipo_anagrafica_2"
    )[0].id;
    const anagResponse = await apiAnagraficaByFascicolo.fetch({
      fascicoloId: fascicolo.id,
    });
    const anagrafica =
      anagResponse.data.data.anagrafica.length > 0
        ? anagResponse.data.data.anagrafica
        : null;
    const cliente = anagrafica.filter(
      (x) =>
        x.IsDeleted === false &&
        x.anagrafica_fascicolos.some(
          (y) =>
            y.FascicoloId === fascicolo.id &&
            (y.TipoAnagId === tipoAnagId1 || y.TipoAnagId === tipoAnagId2)
        )
    )[0];
    let anagraficaCreationDate = new Date(
      cliente.anagrafica_identificaziones[0].IdentifiedOn
    );
    if (date !== null && anagraficaCreationDate <= date) {
      const newDate = new Date(date);

      currentIndicatore.date = newDate;
      setCurrentIndicatore((prevState) => ({ ...prevState, date: newDate }));      
    } else {
      enqueueSnackbar(
        "La data deve essere uguale o superiore alla data di identificazione del cliente",
        { variant: "error" }
      );
    }
  };
  const emptyIndicatoreModel = () => {
    let data = new Date();
    if (
      currentIndicatore.date !== null &&
      currentIndicatore.date !== undefined
    ) {
      data = anagraficaDataIdent;
    }

    const indAnomaliaModel = new indicatoreAnomaliaModel(0, data);
    indAnomaliaModel.providedBy =
      currentIndicatore.providedBy !== undefined
        ? currentIndicatore.providedBy
        : sharedDatas.getPropertyByName("userId");
    indAnomaliaModel.createdBy = loggedUserId;

    const resultArr = [];
    const arrAccordionExpandedItems = [];
    for (let i = 0; i < baseIndAnomaliaList.length; i++) {
      resultArr.push({
        id:
          baseIndAnomaliaList[i].point + "." + baseIndAnomaliaList[i].subPoint,
        checked: false,
      });

      if (baseIndAnomaliaList[i].parentId === 0) {
        arrAccordionExpandedItems.push({
          id: baseIndAnomaliaList[i].point,
          expanded: false,
        });
      }
    }
    indAnomaliaModel.result = resultArr;
    setAccordionExpandedArr(arrAccordionExpandedItems);

    return indAnomaliaModel;
  };
  const handleAddIndicatoreClick = async () => {
    const newItem = emptyIndicatoreModel();

    setCurrentIndicatore(newItem);
    setCurrentIndicatoreOriginal(newItem);

    setShowIndAnomaliaForm(true);
  };
  const handleCancelClick = () => {
    setCurrentIndicatore(emptyIndicatoreModel());
    setCurrentIndicatoreOriginal(emptyIndicatoreModel());

    const curInd = emptyIndicatoreModel();
    curInd.date = new Date();
    setCurrentIndicatore(curInd);

    setShowIndAnomaliaForm(false);
  };
  const handleSelectedIndicatoreClick = async (indicatore) => {
    await selectIndicatore(indicatore);
  };
  const handleSOSClick = async (indicatore) => {
    const result = indicatore.result;

    //Costruisco il testo della SOS
    let arrSosDesc = "";
    for (let i = 0; i < result.length; i++) {
      const resultItem = result[i];

      if (resultItem.checked) {
        const itemPoint = parseInt(resultItem.id.split(".")[0]);
        const itemSubPoint = parseInt(resultItem.id.split(".")[1]);
        const indicatoreItem = baseIndAnomaliaList.filter(
          (x) => x.point === itemPoint && x.subPoint === itemSubPoint
        )[0];
        
        if (itemSubPoint > 0) {
          arrSosDesc = arrSosDesc + "- " + indicatoreItem.description + "\n";
        }
      }
    }

    postal.publish({
      channel: "fascicolo",
      topic: "sos.add",
      data: {
        sosDesc: arrSosDesc,
      },
    });
  };
  const selectIndicatore = async (indicatore) => {
    try {
      setBackDropOpen(true);
      setCurrentIndicatore(indicatore);
      setCurrentIndicatoreOriginal(indicatore);
      setShowIndAnomaliaForm(true);

      let result = Object.assign([], indicatore.result);
      let accordionArr = Object.assign([], accordionExpandedArr);
      for (let i = 0; i < result.length; i++) {
        const indicatoreItem = result[i];

        const itemPoint = parseInt(indicatoreItem.id.split(".")[0]);
        const itemSubPoint = parseInt(indicatoreItem.id.split(".")[1]);
        const baseIndicatoreItem = baseIndAnomaliaList.filter(
          (x) => x.point === itemPoint && x.subPoint === itemSubPoint
        )[0];

        if (baseIndicatoreItem.parentId === 0) {
          const accordionItem = {
            id: itemPoint,
            expanded: indicatoreItem.checked,
          };
          const accordionIndex = accordionArr.findIndex(
            (obj) => obj.id === itemPoint
          );
          accordionArr[accordionIndex] = accordionItem;
        }
      }

      setAccordionExpandedArr(accordionArr);
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "IndicatoreAnomaliaForm",
        method: "selectIndicatore",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const handleDeleteIndicatoreClick = (indicatore) => {
    setIdToPerform(indicatore.id);
    setShowDeleteModal(true);
  };
  const handleSaveClick = async () => {
    try {
      setBackDropOpen(true);
      let canIProceed = false;

      if (
        currentIndicatore !== undefined &&
        currentIndicatore !== null &&
        currentIndicatore.date !== undefined &&
        currentIndicatore.date !== null &&
        currentIndicatore.providedBy !== undefined &&
        currentIndicatore.providedBy !== null &&
        currentIndicatore.providedBy !== ""
      ) {
        canIProceed = true;
      }

      if (canIProceed) {
        //Se sto creando l'indicatore
        if (currentIndicatore.id === 0) {
          const newDateD = new Date(currentIndicatore.date);
          const newDate = new Date(
            Date.UTC(
              newDateD.getFullYear(),
              newDateD.getMonth(),
              newDateD.getDate(),
              newDateD.getHours(),
              newDateD.getMinutes(),
              newDateD.getSeconds()
            )
          );

          const addIndicatoreResponse = await apiAddIndicatore.fetch({
            fascicoloId: fascicolo.id,
            date: newDate,
            providedBy: currentIndicatore.providedBy,
            result: JSON.stringify(currentIndicatore.result),
            note: currentIndicatore.note,
          });
          if (addIndicatoreResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result =
              addIndicatoreResponse.data.data.insert_indicatore_anomalia_one;
            let indicatoreResultModel = new indicatoreAnomaliaModel(
              result.Id,
              result.Date,
              result.ProvidedBy,
              JSON.parse(result.Result),
              result.Note,
              result.CreatedOn,
              result.ModifiedOn,
              result.CreatedBy,
              result.ModifiedBy
            );

            //Aggiungo la Audit per tracciare l'azione
            const newValues = indicatoreResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: "indicatore_anomalia",
              operation: "Create",
              entityType: "indicatore_anomalia",
              entityId: result.Id.toString(),
              oldValues: "",
              newValues: JSON.stringify(newValues),
            });

            //Aggiorno l'elenco degli indicatori
            const indicatori = Object.assign([], currentIndicatoriList);
            indicatori.unshift(indicatoreResultModel);
            setCurrentIndicatoriList(indicatori);
            setCurrentIndicatore(indicatoreResultModel);
            setCurrentIndicatoreOriginal(indicatoreResultModel);

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }

        //Se la sto modificando
        else {
          const newDateD = new Date(currentIndicatore.date);
          const newDate = new Date(
            Date.UTC(
              newDateD.getFullYear(),
              newDateD.getMonth(),
              newDateD.getDate(),
              newDateD.getHours(),
              newDateD.getMinutes(),
              newDateD.getSeconds()
            )
          );

          const updateIndicatoreResponse = await apiUpdateIndicatore.fetch({
            id: currentIndicatore.id,
            fascicoloId: fascicolo.id,
            date: newDate,
            providedBy: currentIndicatore.providedBy,
            result: JSON.stringify(currentIndicatore.result),
            note: currentIndicatore.note,
          });
          if (updateIndicatoreResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result =
              updateIndicatoreResponse.data.data.update_indicatore_anomalia
                .returning[0];
            let indicatoreResultModel = new indicatoreAnomaliaModel(
              result.Id,
              result.Date,
              result.ProvidedBy,
              JSON.parse(result.Result),
              result.Note,
              result.CreatedOn,
              result.ModifiedOn,
              result.CreatedBy,
              result.ModifiedBy
            );

            //Aggiungo la Audit per tracciare l'azione
            const newValues = indicatoreResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: "indicatore_anomalia",
              operation: "Update",
              entityType: "indicatore_anomalia",
              entityId: result.Id.toString(),
              oldValues: JSON.stringify(currentIndicatoreOriginal),
              newValues: JSON.stringify(newValues),
            });

            //Aggiorno l'elenco degli indicatori
            const indicatori = Object.assign([], currentIndicatoriList);
            const index = indicatori.findIndex(
              (item) => item.id === indicatoreResultModel.id
            );
            indicatori[index] = indicatoreResultModel;

            setCurrentIndicatoriList(indicatori);
            setCurrentIndicatore(indicatoreResultModel);
            setCurrentIndicatoreOriginal(indicatoreResultModel);

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }

        //Aggiorno la data di ultima modifica del fascicolo
        await apiUpdateFascicolo.fetch({
          id: fascicolo.id,
          name: fascicolo.name,
          date: fascicolo.date,
          isPrivate: fascicolo.isPrivate,
        });
      } else {
        enqueueSnackbar(t("error.missingFields"), { variant: "error" });
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "IndicatoreAnomaliaForm",
        method: "handleSaveClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      reloadStatusFascicolo();
    }
  };
  //#endregion

  //#region Modal Delete
  const handleModalDeleteClose = () => {
    setIdToPerform(0);
    setShowDeleteModal(false);
  };
  const handleModalDeleteOkClick = async () => {
    try {
      setBackDropOpen(true);

      if (idToPerform > 0) {
        const delIndResponse = await apiDeleteIndicatore.fetch({
          id: idToPerform,
          fascicoloId: fascicolo.id,
        });
        if (delIndResponse.data.errors !== undefined) {
          enqueueSnackbar(t("error.generale"), { variant: "error" });
        } else {
          //Aggiungo la Audit per tracciare l'azione
          const itemById = JSON.stringify(
            currentIndicatoriList.filter((item) => item.id === idToPerform)[0]
          );
          apiAddAudit.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            area: "indicatore_anomalia",
            operation: "Delete",
            entityType: "indicatore_anomalia",
            entityId: idToPerform.toString(),
            oldValues: itemById,
            newValues: "",
          });

          //Aggiorno l'elenco degli indicatori
          const indicatori = Object.assign([], currentIndicatoriList);
          const newIndicatori = indicatori.filter(
            (item) => item.id !== idToPerform
          );
          setCurrentIndicatoriList(newIndicatori);

          setCurrentIndicatore(emptyIndicatoreModel());
          setCurrentIndicatoreOriginal(emptyIndicatoreModel());

          setShowIndAnomaliaForm(false);
          enqueueSnackbar(t("message.success"), { variant: "success" });
        }

        //Aggiorno la data di ultima modifica del fascicolo
        await apiUpdateFascicolo.fetch({
          id: fascicolo.id,
          name: fascicolo.name,
          date: fascicolo.date,
          isPrivate: fascicolo.isPrivate,
        });
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "IndicatoreAnomaliaForm",
        method: "handleModalDeleteOkClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setIdToPerform(0);
      setShowDeleteModal(false);
      setBackDropOpen(false);
      reloadStatusFascicolo();
    }
  };
  //#endregion

  //#region Return Graphics
  return (
    <Fragment>
      <VaporPage.Section>
        <Loading open={backDropOpen} />

        {showDeleteModal && idToPerform > 0 ? (
          <ModalDelete
            show={showDeleteModal}
            onClose={handleModalDeleteClose}
            onOk={handleModalDeleteOkClick}
          ></ModalDelete>
        ) : null}

        {isDataLoaded ? (
          <Fragment>
            <Stack
              direction='row'
              spacing={2}
              divider={<Divider orientation='vertical' flexItem />}
            >
              <Stack direction='column' spacing={1} sx={{ width: "25%" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={it}
                >
                  <DatePicker
                    label='Data'
                    closeOnSelect
                    format='dd/MM/yyyy'
                    mask='__/__/____'
                    minDate={
                      isDateValid(anagraficaDataIdent)
                        ? new Date(anagraficaDataIdent)
                        : null
                    }
                    value={
                      isDateValid(anagraficaDataIdent)
                        ? new Date(anagraficaDataIdent)
                        : null
                    }
                    onChange={handleChangeDate}
                    disabled={currentIndicatore.id > 0}
                    slotProps={{
                      textField: {
                        placeholder: "dd/MM/yyyy",
                        size: "small",
                        fullWidth : true
                      },
                    }}
                  />
                </LocalizationProvider>

                <Autocomplete
                  id='ddlUtenti'
                  key={uuidv4()}
                  size='small'
                  fullWidth
                  options={utentiList}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.completeName}
                  defaultValue={
                    utentiList.filter(
                      (item) => item.userId === currentIndicatore.providedBy
                    )[0]
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("valutazione.form.professionista")}
                      required
                    />
                  )}
                  onChange={handleChange}
                  disabled={currentIndicatore.id > 0}
                />

                <Button
                  variant='contained'
                  size='small'
                  endIcon={<AddIcon />}
                  onClick={handleAddIndicatoreClick}
                  disabled={!isUserAllowedToManage}
                >
                  {t("indicatori.add")}
                </Button>

                <Divider variant='standard' sx={{ pt: 1 }}></Divider>

                {currentIndicatoriList.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table size='small' sx={{ mt: 2 }}>
                      <TableBody>
                        {currentIndicatoriList
                          .map((item) => (
                            <TableRow
                              key={uuidv4()}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              hover
                              selected={currentIndicatore.id === item.id}
                            >
                              <TableCell
                                key={uuidv4()}
                                component='th'
                                scope='row'
                              >
                                <Stack
                                  direction='row'
                                  justifyContent='center'
                                  alignItems='center'
                                >
                                  <Stack direction='column'>
                                    <ExtendedTypography
                                      variant='titleSmall'
                                      color='primary.main'
                                    >
                                      {t("indicatori.performedBy")}{" "}
                                      {
                                        allUtentiList.filter(
                                          (x) => x.userId === item.providedBy
                                        )[0].completeName
                                      }{" "}
                                      in data{" "}
                                      {new Date(item.date).toLocaleDateString()}
                                    </ExtendedTypography>
                                  </Stack>
                                  <Box sx={{ flexGrow: 1 }} />
                                  <Stack direction='row'>
                                    <IconButton
                                      edge='end'
                                      aria-label='select'
                                      color='primary'
                                      onClick={() =>
                                        handleSelectedIndicatoreClick(item)
                                      }
                                      sx={{ mr: "1px !important" }}
                                    >
                                      <ModeEditIcon fontSize='small' />
                                    </IconButton>
                                    <IconButton
                                      edge='end'
                                      aria-label='select'
                                      color='primary'
                                      onClick={() => handleSOSClick(item)}
                                      sx={{ mr: "1px !important" }}
                                    >
                                      <SosIcon fontSize='small' />
                                    </IconButton>
                                    {currentIndicatore !== null &&
                                    currentIndicatore.providedBy ===
                                      loggedUserId ? (
                                      <IconButton
                                        edge='end'
                                        aria-label='delete'
                                        color='error'
                                        onClick={() =>
                                          handleDeleteIndicatoreClick(item)
                                        }
                                        sx={{ mr: "1px !important" }}
                                      >
                                        <DeleteIcon fontSize='small' />
                                      </IconButton>
                                    ) : null}
                                  </Stack>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoData size='subtitle' />
                )}
              </Stack>
              <Stack direction='column' spacing={1} sx={{ width: "75%" }}>
                {showIndAnomaliaForm ? (
                  <Fragment>
                    {!isUserAllowedToManage ? (
                      <Stack direction='column' sx={{ width: "100%" }}>
                        <Alert variant='outlined' severity='warning'>
                          {t("indicatori.desc")}
                        </Alert>
                      </Stack>
                    ) : null}

                    <ExtendedTypography
                      variant='titleSmall'
                      color='primary.main'
                    >
                      {t("indicatori.sectionA")}
                    </ExtendedTypography>
                    <Divider variant='standard' />

                    <Stack
                      direction='column'
                      spacing={0}
                      justifyContent='center'
                      alignItems='center'
                    >
                      {baseIndAnomaliaList
                        .filter((x) => x.section === "A")
                        .map((item) =>
                          item.parentId === 0 && item.isVisible === true ? (
                            <Fragment key={uuidv4()}>
                              <ExtendedAccordion
                                expanded={
                                  accordionExpandedArr.filter(
                                    (x) => x.id === item.point
                                  )[0].expanded
                                }
                              >
                                <AccordionSummary>
                                  <Stack
                                    direction='row'
                                    spacing={2}
                                    justifyContent='center'
                                    alignItems='center'
                                  >
                                    <Stack
                                      direction='column'
                                      sx={{ width: "94%" }}
                                    >
                                      <ExtendedTypography
                                        variant='titleSmall'
                                        color='primary.main'
                                        sx={{ width: "100% !important" }}
                                      >
                                        {item.point + ". " + item.description}
                                      </ExtendedTypography>
                                    </Stack>
                                    <Stack
                                      direction='column'
                                      sx={{ width: "6%" }}
                                    >
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              id={
                                                "switch" +
                                                item.point +
                                                "." +
                                                item.subPoint
                                              }
                                              defaultChecked={
                                                currentIndicatore.result.filter(
                                                  (x) =>
                                                    x.id ===
                                                    item.point.toString() +
                                                      "." +
                                                      item.subPoint.toString()
                                                )[0].checked
                                              }
                                              onChange={handleChange}
                                              disabled={!isUserAllowedToManage}
                                            />
                                          }
                                          label={
                                            currentIndicatore.result.filter(
                                              (x) =>
                                                x.id ===
                                                item.point.toString() +
                                                  "." +
                                                  item.subPoint.toString()
                                            )[0].checked
                                              ? "Sì"
                                              : "No"
                                          }
                                        />
                                      </FormGroup>
                                    </Stack>
                                  </Stack>
                                </AccordionSummary>
                                <AccordionDetails
                                  sx={{
                                    pl: "15px !important",
                                    pr: "15px !important",
                                  }}
                                >
                                  <TableContainer
                                    component={Paper}
                                    sx={{ mt: 2 }}
                                  >
                                    <Table size='small'>
                                      <TableBody>
                                        {baseIndAnomaliaList
                                          .filter(
                                            (x) =>
                                              x.parentId === item.id &&
                                              x.isVisible === true
                                          )
                                          .map((subItem) => (
                                            <TableRow key={subItem.id} hover>
                                              <TableCell
                                                align='left'
                                                width='90%'
                                              >
                                                <ExtendedTypography variant='subtitle2'>
                                                  {subItem.point +
                                                    "." +
                                                    subItem.subPoint +
                                                    " " +
                                                    subItem.description}
                                                </ExtendedTypography>
                                              </TableCell>
                                              <TableCell
                                                align='left'
                                                width='10%'
                                              >
                                                <FormGroup>
                                                  <FormControlLabel
                                                    control={
                                                      <Switch
                                                        id={
                                                          "switch" +
                                                          subItem.point +
                                                          "." +
                                                          subItem.subPoint
                                                        }
                                                        defaultChecked={
                                                          currentIndicatore.result.filter(
                                                            (x) =>
                                                              x.id ===
                                                              subItem.point.toString() +
                                                                "." +
                                                                subItem.subPoint.toString()
                                                          )[0].checked
                                                        }
                                                        onChange={handleChange}
                                                        disabled={
                                                          !isUserAllowedToManage
                                                        }
                                                      />
                                                    }
                                                    label={
                                                      currentIndicatore.result.filter(
                                                        (x) =>
                                                          x.id ===
                                                          subItem.point.toString() +
                                                            "." +
                                                            subItem.subPoint.toString()
                                                      )[0].checked
                                                        ? "Sì"
                                                        : "No"
                                                    }
                                                  />
                                                </FormGroup>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </AccordionDetails>
                              </ExtendedAccordion>
                            </Fragment>
                          ) : null
                        )}
                    </Stack>

                    <ExtendedTypography
                      variant='titleSmall'
                      color='primary.main'
                      sx={{ mt: "30px !important" }}
                    >
                      {t("indicatori.sectionB")}
                    </ExtendedTypography>
                    <Divider variant='standard' />

                    <Stack
                      direction='column'
                      spacing={0}
                      justifyContent='center'
                      alignItems='center'
                    >
                      {baseIndAnomaliaList
                        .filter((x) => x.section === "B")
                        .map((item) =>
                          item.parentId === 0 && item.isVisible === true ? (
                            <Fragment key={uuidv4()}>
                              <ExtendedAccordion
                                expanded={
                                  accordionExpandedArr.filter(
                                    (x) => x.id === item.point
                                  )[0].expanded
                                }
                              >
                                <AccordionSummary>
                                  <Stack
                                    direction='row'
                                    spacing={2}
                                    justifyContent='center'
                                    alignItems='center'
                                  >
                                    <Stack
                                      direction='column'
                                      sx={{ width: "94%" }}
                                    >
                                      <ExtendedTypography
                                        variant='titleSmall'
                                        color='primary.main'
                                        sx={{ width: "100% !important" }}
                                      >
                                        {item.point + ". " + item.description}
                                      </ExtendedTypography>
                                    </Stack>
                                    <Stack
                                      direction='column'
                                      sx={{ width: "6%" }}
                                    >
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              id={
                                                "switch" +
                                                item.point +
                                                "." +
                                                item.subPoint
                                              }
                                              defaultChecked={
                                                currentIndicatore.result.filter(
                                                  (x) =>
                                                    x.id ===
                                                    item.point.toString() +
                                                      "." +
                                                      item.subPoint.toString()
                                                )[0].checked
                                              }
                                              onChange={handleChange}
                                              disabled={!isUserAllowedToManage}
                                            />
                                          }
                                          label={
                                            currentIndicatore.result.filter(
                                              (x) =>
                                                x.id ===
                                                item.point.toString() +
                                                  "." +
                                                  item.subPoint.toString()
                                            )[0].checked
                                              ? "Sì"
                                              : "No"
                                          }
                                        />
                                      </FormGroup>
                                    </Stack>
                                  </Stack>
                                </AccordionSummary>
                                <AccordionDetails
                                  sx={{
                                    pl: "15px !important",
                                    pr: "15px !important",
                                  }}
                                >
                                  <TableContainer
                                    component={Paper}
                                    sx={{ mt: 2 }}
                                  >
                                    <Table size='small'>
                                      <TableBody>
                                        {baseIndAnomaliaList
                                          .filter(
                                            (x) =>
                                              x.parentId === item.id &&
                                              x.isVisible === true
                                          )
                                          .map((subItem) => (
                                            <TableRow key={subItem.id} hover>
                                              <TableCell
                                                align='left'
                                                width='90%'
                                              >
                                                <ExtendedTypography variant='subtitle2'>
                                                  {subItem.point +
                                                    "." +
                                                    subItem.subPoint +
                                                    " " +
                                                    subItem.description}
                                                </ExtendedTypography>
                                              </TableCell>
                                              <TableCell
                                                align='left'
                                                width='10%'
                                              >
                                                <FormGroup>
                                                  <FormControlLabel
                                                    control={
                                                      <Switch
                                                        id={
                                                          "switch" +
                                                          subItem.point +
                                                          "." +
                                                          subItem.subPoint
                                                        }
                                                        defaultChecked={
                                                          currentIndicatore.result.filter(
                                                            (x) =>
                                                              x.id ===
                                                              subItem.point.toString() +
                                                                "." +
                                                                subItem.subPoint.toString()
                                                          )[0].checked
                                                        }
                                                        onChange={handleChange}
                                                        disabled={
                                                          !isUserAllowedToManage
                                                        }
                                                      />
                                                    }
                                                    label={
                                                      currentIndicatore.result.filter(
                                                        (x) =>
                                                          x.id ===
                                                          subItem.point.toString() +
                                                            "." +
                                                            subItem.subPoint.toString()
                                                      )[0].checked
                                                        ? "Sì"
                                                        : "No"
                                                    }
                                                  />
                                                </FormGroup>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </AccordionDetails>
                              </ExtendedAccordion>
                            </Fragment>
                          ) : null
                        )}
                    </Stack>

                    <ExtendedTypography
                      variant='titleSmall'
                      color='primary.main'
                      sx={{ mt: "30px !important" }}
                    >
                      {t("indicatori.sectionC")}
                    </ExtendedTypography>
                    <Divider variant='standard' />

                    <Stack
                      direction='column'
                      spacing={0}
                      justifyContent='center'
                      alignItems='center'
                    >
                      {baseIndAnomaliaList
                        .filter((x) => x.section === "C")
                        .map((item) =>
                          item.parentId === 0 && item.isVisible === true ? (
                            <Fragment key={uuidv4()}>
                              <ExtendedAccordion
                                expanded={
                                  accordionExpandedArr.filter(
                                    (x) => x.id === item.point
                                  )[0].expanded
                                }
                              >
                                <AccordionSummary>
                                  <Stack
                                    direction='row'
                                    spacing={2}
                                    justifyContent='center'
                                    alignItems='center'
                                  >
                                    <Stack
                                      direction='column'
                                      sx={{ width: "94%" }}
                                    >
                                      <ExtendedTypography
                                        variant='titleSmall'
                                        color='primary.main'
                                        sx={{ width: "100% !important" }}
                                      >
                                        {item.point + ". " + item.description}
                                      </ExtendedTypography>
                                    </Stack>
                                    <Stack
                                      direction='column'
                                      sx={{ width: "6%" }}
                                    >
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              id={
                                                "switch" +
                                                item.point +
                                                "." +
                                                item.subPoint
                                              }
                                              defaultChecked={
                                                currentIndicatore.result.filter(
                                                  (x) =>
                                                    x.id ===
                                                    item.point.toString() +
                                                      "." +
                                                      item.subPoint.toString()
                                                )[0].checked
                                              }
                                              onChange={handleChange}
                                              disabled={!isUserAllowedToManage}
                                            />
                                          }
                                          label={
                                            currentIndicatore.result.filter(
                                              (x) =>
                                                x.id ===
                                                item.point.toString() +
                                                  "." +
                                                  item.subPoint.toString()
                                            )[0].checked
                                              ? "Sì"
                                              : "No"
                                          }
                                        />
                                      </FormGroup>
                                    </Stack>
                                  </Stack>
                                </AccordionSummary>
                                <AccordionDetails
                                  sx={{
                                    pl: "15px !important",
                                    pr: "15px !important",
                                  }}
                                >
                                  <TableContainer
                                    component={Paper}
                                    sx={{ mt: 2 }}
                                  >
                                    <Table size='small'>
                                      <TableBody>
                                        {baseIndAnomaliaList
                                          .filter(
                                            (x) =>
                                              x.parentId === item.id &&
                                              x.isVisible === true
                                          )
                                          .map((subItem) => (
                                            <TableRow key={subItem.id} hover>
                                              <TableCell
                                                align='left'
                                                width='90%'
                                              >
                                                <ExtendedTypography variant='subtitle2'>
                                                  {subItem.point +
                                                    "." +
                                                    subItem.subPoint +
                                                    " " +
                                                    subItem.description}
                                                </ExtendedTypography>
                                              </TableCell>
                                              <TableCell
                                                align='left'
                                                width='10%'
                                              >
                                                <FormGroup>
                                                  <FormControlLabel
                                                    control={
                                                      <Switch
                                                        id={
                                                          "switch" +
                                                          subItem.point +
                                                          "." +
                                                          subItem.subPoint
                                                        }
                                                        defaultChecked={
                                                          currentIndicatore.result.filter(
                                                            (x) =>
                                                              x.id ===
                                                              subItem.point.toString() +
                                                                "." +
                                                                subItem.subPoint.toString()
                                                          )[0].checked
                                                        }
                                                        onChange={handleChange}
                                                        disabled={
                                                          !isUserAllowedToManage
                                                        }
                                                      />
                                                    }
                                                    label={
                                                      currentIndicatore.result.filter(
                                                        (x) =>
                                                          x.id ===
                                                          subItem.point.toString() +
                                                            "." +
                                                            subItem.subPoint.toString()
                                                      )[0].checked
                                                        ? "Sì"
                                                        : "No"
                                                    }
                                                  />
                                                </FormGroup>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </AccordionDetails>
                              </ExtendedAccordion>
                            </Fragment>
                          ) : null
                        )}
                    </Stack>

                    <ExtendedTypography
                      variant='titleSmall'
                      color='primary.main'
                      sx={{ mt: "30px !important" }}
                    >
                      {t("fascicolo.fascicoloAdd.note")}
                    </ExtendedTypography>
                    <Divider variant='standard' />

                    <Stack
                      direction='column'
                      spacing={0}
                      justifyContent='center'
                      alignItems='center'
                    >
                      <TextField
                        id='txtNote'
                        key={uuidv4()}
                        label=''
                        size='small'
                        fullWidth
                        multiline
                        rows={5}
                        defaultValue={currentIndicatore.note}
                        onChange={handleChange}
                        inputProps={{ autocomplete: "nope" }}
                        disabled={!isUserAllowedToManage}
                      />
                    </Stack>
                  </Fragment>
                ) : null}
              </Stack>
            </Stack>
          </Fragment>
        ) : null}
      </VaporPage.Section>
      {isDataLoaded && showIndAnomaliaForm && isUserAllowedToManage ? (
        <VaporToolbar
          variant='regular'
          size='medium'
          withoutAppBar={false}
          contentRight={
            <Stack direction='row' spacing={2}>
              <Button
                variant='outlined'
                color='secondary'
                size='medium'
                endIcon={<CancelIcon />}
                onClick={handleCancelClick}
              >
                {t("actions.annulla")}
              </Button>
              <Button
                variant='contained'
                size='medium'
                endIcon={<SaveIcon />}
                onClick={handleSaveClick}
              >
                {t("actions.salva")}
              </Button>
            </Stack>
          }
        ></VaporToolbar>
      ) : null}
    </Fragment>
  );
  //#endregion
};
